import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/group-hotel-selection-page/group-hotel-selection-page.module').then( m => m.GroupHotelSelectionPagePageModule)  
  },
  {
    path: 'group-hotel-selection-page',
    loadChildren: () => import('./pages/group-hotel-selection-page/group-hotel-selection-page.module').then( m => m.GroupHotelSelectionPagePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reservation',
    loadChildren: () => import('./pages/reservation/reservation.module').then( m => m.ReservationPageModule)
  },
  {
    path: 'weather',
    loadChildren: () => import('./pages/weather/weather.module').then( m => m.WeatherPageModule)
  },
  {
    path: 'alakart-modal',
    loadChildren: () => import('./pages/alakart-modal/alakart-modal.module').then( m => m.AlakartModalPageModule)
  },
  {
    path: 'activity',
    loadChildren: () => import('./pages/activity/activity.module').then( m => m.ActivityPageModule)
  },
  {
    path: 'ayarlar',
    loadChildren: () => import('./pages/ayarlar/ayarlar.module').then( m => m.AyarlarPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'useractivities',
    loadChildren: () => import('./pages/useractivities/useractivities.module').then( m => m.UseractivitiesPageModule)
  },
  {
    path: 'menu-with-images',
    loadChildren: () => import('./pages/menu-with-images/menu-with-images.module').then( m => m.MenuWithImagesPageModule)
  },
  {
    path: 'menu-left-image',
    loadChildren: () => import('./pages/menu-left-image/menu-left-image.module').then( m => m.MenuLeftImagePageModule)
  },
  {
    path: 'detailmenu',
    loadChildren: () => import('./pages/detailmenu/detailmenu.module').then( m => m.DetailmenuPageModule)
  },
  {
    path: 'doubled-menu',
    loadChildren: () => import('./pages/doubled-menu/doubled-menu.module').then( m => m.DoubledMenuPageModule)
  },
  {
    path: 'product-list',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'task',
    loadChildren: () => import('./pages/task/task.module').then( m => m.TaskPageModule)
  },
  {
    path: 'basket',
    loadChildren: () => import('./pages/basket/basket.module').then( m => m.BasketPageModule),
    data: { preload: true }
  },
  {
    path: 'detail',
    loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'trace-list',
    loadChildren: () => import('./pages/trace-list/trace-list.module').then( m => m.TraceListPageModule)
  },
  {
    path: 'full-image-viewer',
    loadChildren: () => import('./pages/full-image-viewer/full-image-viewer.module').then( m => m.FullImageViewerPageModule)
  },
  {
    path: 'accommodations',
    loadChildren: () => import('./pages/accommodations/accommodations.module').then( m => m.AccommodationsPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'loyalty',
    loadChildren: () => import('./pages/loyalty/loyalty.module').then( m => m.LoyaltyPageModule)
  },
  {
    path: 'balance',
    loadChildren: () => import('./pages/balance/balance.module').then( m => m.BalancePageModule)
  },
  {
    path: 'screen3-d',
    loadChildren: () => import('./pages/screen3-d/screen3-d.module').then( m => m.Screen3DPageModule)
  },
  {
    path: 'barcode-scanner',
    loadChildren: () => import('./pages/barcode-scanner/barcode-scanner.module').then( m => m.BarcodeScannerPageModule)
  },
  {
    path: 'mobile-order-list',
    loadChildren: () => import('./pages/mobile-order-list/mobile-order-list.module').then( m => m.MobileOrderListPageModule)
  },
  {
    path: 'language-selector',
    loadChildren: () => import('./pages/language-selector/language-selector.module').then( m => m.LanguageSelectorPageModule)
  },
  {
    path: 'iframer',
    loadChildren: () => import('./pages/iframer/iframer.module').then( m => m.IframerPageModule)
  },
  {
    path: 'kvkk',
    loadChildren: () => import('./pages/kvkk/kvkk.module').then( m => m.KvkkPageModule)
  },
  {
    path: 'information',
    loadChildren: () => import('./pages/information/information.module').then( m => m.InformationPageModule)
  },
  {
    path: 'rating-modal',
    loadChildren: () => import('./pages/rating-modal/rating-modal.module').then( m => m.RatingModalPageModule)
  },
  {
    path: 'searchable',
    loadChildren: () => import('./pages/searchable/searchable.module').then( m => m.SearchablePageModule)
  },
  {
    path: 'sss',
    loadChildren: () => import('./pages/sss/sss.module').then( m => m.SssPageModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('./pages/transfer/transfer.module').then( m =>m.TransferPageModule )
  },
  {
    path: 'tour-ticket',
    loadChildren: () => import('./pages/tour-ticket/tour-ticket.module').then( m =>m.TourTicketPageModule )
  },
  {
    path: 'advertisement-modal',
    loadChildren: () => import('./pages/advertisement-modal/advertisement-modal.module').then( m => m.AdvertisementModalPageModule)
  },
  {
    path: 'galery',
    loadChildren: () => import('./pages/galery/galery.module').then( m => m.GaleryPageModule)
  },
  {
    path: 'success-payment',
    loadChildren: () => import('./pages/success-payment/success-payment.module').then( m => m.SuccessPaymentPageModule)
  },
  {
    path: 'fail-payment',
    loadChildren: () => import('./pages/fail-payment/fail-payment.module').then( m => m.FailPaymentPageModule)
  },
  {
    path: 'toolbar-custom',
    loadChildren: () => import('./directives/toolbar-custom/toolbar-custom.module').then( m => m.ToolbarCustomPageModule)
  },
  {
    path: 'precheckin',
    loadChildren: () => import('./pages/precheckin/precheckin.module').then( m => m.PrecheckinPageModule)
  },
  {
    path: 'kvkk-modal',
    loadChildren: () => import('./pages/kvkk-modal/kvkk-modal.module').then( m => m.KvkkModalPageModule)
  },
  {
    path: 'door-lock',
    loadChildren: () => import('./pages/door-lock/door-lock.module').then( m => m.DoorLockPageModule)
  },  {
    path: 'door-lock-login',
    loadChildren: () => import('./pages/door-lock-login/door-lock-login.module').then( m => m.DoorLockLoginPageModule)
  },
  {
    path: 'pdf-viewer',
    loadChildren: () => import('./pages/pdf-viewer/pdf-viewer.module').then( m => m.PdfViewerPageModule)
  },
  {
    path: 'qrcode',
    loadChildren: () => import('./pages/qrcode/qrcode.module').then( m => m.QRCodePageModule)
  }





  
];

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
