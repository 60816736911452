import { Component, HostBinding, NgZone, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpService } from './services/http.service';
import { MatIconModule } from '@angular/material/icon';
import { SharedService } from './services/shared.service';

import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { StorageService } from './services/storage.service';
import { RouteLinkParametersService } from './services/route-link-parameters.service';
import { redirectModel } from './models/redirectModel';
import { ModalTpyes } from './models/enums/modal-types.enum';
import { ModalControllerService } from './services/modalController.service';
import { ThemeService } from './services/theme.service';
import { PlatformDetectionService } from './services/platform-detection.service';
import { TranslatingService } from './services/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { EnumLanguageType } from './models/enums/enum-language-type.enum';
import { MenuTypes } from './models/enums/menu-types.enum';
import { Router } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';
import { ToasterService } from './services/toaster.service';
import { MobileMenuService } from './services/mobile-menu.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { PluginListenerHandle } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [HttpService, MatIconModule]
})

export class AppComponent implements OnInit {

  public menuObjectList: any;
  public selectedIndex = 0;
  public hotelName: string = "";
  public imageUrl: string = "";
  private menuItemName: string = "";
  StaticBottomMenu = [
    { title: "profil", icon: "perm_identity", routeURL: '/login' },
    { title: "dashboard", icon: "show_chart", routeURL: '/dashboard' },
    { title: "weather", icon: "wb_cloudy", routeURL: '/weather' },
    { title: "messages", icon: "email", routeURL: '' },
    { title: "options", icon: "settings", routeURL: '/ayarlar' },
    { title: "logout", icon: "exit_to_app", routeURL: '' }   
  ]


  //  darkModeOn: boolean = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
  //   @HostBinding("class")
  //   get themeMode() {
  //     return this.darkModeOn ? "dark-theme" : "light-theme";
  //   };

  public appPages = [];
  otelName;
  otelLogoURL;
  ShowWeather;
  public labels = [];
  themeColor;
  themeBackgroundColor;
  showTabBar: boolean;
  tabBarVisibility: boolean = false;
  anaMenu: any;
  goServiceOpenObject: any;
  mobileOrderIsShowing: boolean;
  reservationBottomMenuIsShowing: boolean;
  serviceIsShowing: boolean;
  mobileOrderMenuId: any;
  serviceMenuId: any;
  whatsAppIsShowing: any;
  whatsAppPhoneNumber: string = "";
  whatsAppText: string = "";
  whatsappHREF: string = "";
  bottomNavigationBarShowForIos = false
  deviceLanguage: EnumLanguageType; //import("c:/Users/Emre/Desktop/SednaMobile/src/app/models/enums/enum-language-type.enum").EnumLanguageType;
  showChangeHotelButton: boolean;
  link: string;
  shortcutNotExist: boolean;
  reservationMenuId: any;
  isHotelItem : boolean;
  networkListener!: PluginListenerHandle;
  networkStatus!: boolean
  oneSignalApiKey: any;
  constructor(
    private platform: Platform,
    private mobilemenuservice: MobileMenuService,
    private platformDetection: PlatformDetectionService,
    private statusBar: StatusBar,
    private router: Router,
    private device: Device,
    private translateService: TranslatingService,
    public nativaStorage: NativeStorage,
    private storageService: StorageService,
    private routelink: RouteLinkParametersService,
    private shared: SharedService,
    private toast: ToasterService,
    private modalControllerService: ModalControllerService,
    private themeService: ThemeService,
    private findDeviceLanguage: TranslateService,
    private mobileMenuService: MobileMenuService,
    private InAppBrowser: InAppBrowser,
    private ngZone: NgZone

  ) {
    this.initializeApp().then(res => {
      console.log(this.platform.platforms());
      this.shared.buttomTabBarBoolean.subscribe(res => {
        this.tabBarVisibility = res;
        console.log("tab", this.tabBarVisibility)
      })
      this.shared.share.subscribe(res => {
        console.log("app comp costractor")
        this.storageService.getItem("anaMenu").then(res => {
          if (res == null || res.length === 0) {
          } else {
            this.showChangeHotelButton = res.MobileHotelDefinition.isGroup
            this.otelName = res.MobileHotelDefinition.Name;
            this.otelLogoURL = res.MobileHotelDefinition.MobileApplicationDefinition.LogoUrl;
            this.ShowWeather = res.MobileHotelDefinition.ShowWeather;
            this.appPages = res.MainMenus
            this.storageService.getItem("themeBackgroundColor").then(res => {
              this.themeBackgroundColor = res;
              this.storageService.getItem("themeColor").then(res => {
                this.themeColor = res
                this.themeService.setTheme().then(res => {
                  this.storageService.getItem("BottomNavigationBars").then(res => {
                    for (var i = 0; i < res.length; i++) {
                      switch (res[i].Key) {
                        case "mobileorder":
                          this.mobileOrderIsShowing = res[i].IsShowing
                          this.mobileOrderMenuId = res[i].MenuId
                          break;
                        case "reservation":
                          this.reservationBottomMenuIsShowing = res[i].IsShowing
                          this.reservationMenuId = res[i].MenuId
                          break;
                        case "service":
                          this.serviceIsShowing = res[i].IsShowing
                          this.serviceMenuId = res[i].MenuId
                          break;
                        case "whatsapp":
                          this.whatsAppIsShowing = res[i].IsShowing
                          this.storageService.getItem("BottomNavigationBars").then(result => {
                            console.log(result[3].Whatsapp.PhoneNumber, result[3].Whatsapp.Description)
                            this.whatsAppPhoneNumber = result[3].Whatsapp.PhoneNumber
                            this.whatsAppText = result[3].Whatsapp.Description
                            console.log(this.whatsAppPhoneNumber)
                            this.whatsappHREF = "https://wa.me/" + this.whatsAppPhoneNumber + "?text=" + this.whatsAppText + "";
                            console.log(this.whatsappHREF)
                          })
                          break;

                        default:
                          break;
                      }
                    }
                  })
                })
              });
            });
          }

        }).catch(res => {
          this.routelink.checkNecessaryHotelData().then(res => {
            this.appCompFunc()
          })
        })
      })
      console.log("asdasdad")
    });
  }
  toggleDisplayMode() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    prefersDark.addEventListener('change', (mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
    this.toggleDarkTheme(prefersDark.matches);
  }

  async startNetworkListener(){
    this.networkListener = await Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      this.ngZone.run(() => {
        this.changeStatus(status);
      })
    });



    const status = await Network.getStatus();
    this.changeStatus(status);
  }
  stopNetworkListener(): void {
    if (this.networkListener) this.networkListener.remove();
  }

  changeStatus(status: ConnectionStatus): void {
    
    this.networkStatus = status?.connected;
    if (!this.networkStatus) {
     
      this.stopNetworkListener();
     this.showNetworkDisconnectedPage();

    }
  }
  async showNetworkDisconnectedPage(): Promise<void> {
    
      const modal = await this.modalControllerService.presentModal(ModalTpyes.networkPage,'');
      
  
      this.startNetworkListener();
    
  }
  // Add or remove the "dark" class based on if the media query matches
  private toggleDarkTheme(shouldAdd:boolean) {
    // alert(JSON.stringify(shouldAdd))
    document.body.classList.toggle('dark-theme', shouldAdd);
  }

  initializeApp(): Promise<any> {
    return this.platform.ready().then(() => {
      if (this.platformDetection.isIOS()) {
        this.statusBar.hide();
      } else {
        this.statusBar.styleDefault();
      }
      if (this.platformDetection.iphoneXOrUpper(this.platform.width(), this.platform.height())) {
        this.bottomNavigationBarShowForIos = true
      } else {
        this.bottomNavigationBarShowForIos = false
      }
      this.deviceLanguage = this.translateService.languageSelectorFromStringToNumber(this.findDeviceLanguage.getBrowserLang())
      console.log(this.deviceLanguage)
      this.storageService.setItem("LangID", this.deviceLanguage)
      this.translateService.switchLanguage(this.deviceLanguage)
      this.showAdvertising()
      this.toggleDisplayMode()
    });
    
  }
  showAdvertising() {
    setTimeout(() => {
      this.storageService.getItem("anaMenu").then(anaMenu => {

        //showbar kontrolü; grup otel seçim sayfasında olup olmadığımızı gösteren bi değişken, bu sayfada reklam olmasın dediler bizde o yüzden bu parametre kontrolünü buraya ekledik
        if (anaMenu != null && anaMenu != undefined && anaMenu != "" && anaMenu.length !== 0 && this.showTabBar == true) {

        //   OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        //     //alert("User accepted notifications: " + accepted);
        // });
          this.oneSignalApiKey = anaMenu.MobileHotelDefinition.OneSignalAppId
          OneSignal.setAppId(this.oneSignalApiKey);

          console.log(anaMenu.MobileHotelDefinition.AdvertiseUrl)
          if (anaMenu.MobileHotelDefinition.AdvertiseUrl) {
            if (this.compareDateIfItsBetweenTwoGivenDates(anaMenu.MobileHotelDefinition.AdvertiseDateBegin, anaMenu.MobileHotelDefinition.AdvertiseDateEnd)) {
              this.shared.updateIframeLink({ Remark: "", Link: anaMenu.MobileHotelDefinition.AdvertiseUrl })
              this.modalControllerService.presentModal(ModalTpyes.advertisement, "")
            } else {
              console.log("advertise link must be begin and end date!!")
            }
          } else {
            console.log('advertise link not exist!');
          }

        } else {
          // alert("else")
          setTimeout(() => {
            this.showAdvertising()
          }, 3000);
        }
      }).catch(err => {
        // alert("catch")
        setTimeout(() => { this.showAdvertising() }, 3000);
      })
    }, 3000);

  }
  compareDateIfItsBetweenTwoGivenDates(dateBegin, dateEnd) {
    var today = new Date();
    var advertisingDateBegin = new Date(dateBegin)
    var advertisingDateEnd = new Date(dateEnd)
    // alert(JSON.stringify(advertisingDateBegin)+" "+ JSON.stringify(today)+" "+ JSON.stringify(advertisingDateEnd))
    if (advertisingDateBegin <= today && today <= advertisingDateEnd) {
      // alert("true")
      return true
    } else {
      // alert("false")
      return false
    }
  }
  appCompFunc() {

    this.storageService.getItem("anaMenu").then(res => {
      if (res == null || res.length === 0) {

      } else {

        this.otelName = res.MobileHotelDefinition.Name;
        this.otelLogoURL = res.MobileHotelDefinition.MobileApplicationDefinition.LogoUrl;
        this.ShowWeather = res.MobileHotelDefinition.ShowWeather;
        this.appPages = res.MainMenus
        this.storageService.getItem("themeBackgroundColor").then(res => {
          this.themeBackgroundColor = res;
          this.storageService.getItem("themeColor").then(res => {
            this.themeColor = res
            this.themeService.setTheme().then(res => {
              this.storageService.getItem("BottomNavigationBars").then(res => {
                for (var i = 0; i < res.length; i++) {
                  switch (res[i].Key) {
                    case "mobileorder":
                      this.mobileOrderIsShowing = res[i].IsShowing
                      this.mobileOrderMenuId = res[i].MenuId
                      break;
                    case "reservation":
                      this.reservationBottomMenuIsShowing = res[i].IsShowing
                      this.reservationMenuId = res[i].MenuId
                      break;
                    case "service":
                      this.serviceIsShowing = res[i].IsShowing
                      this.serviceMenuId = res[i].MenuId
                      break;
                    case "whatsapp":
                      this.whatsAppIsShowing = res[i].IsShowing
                      this.storageService.getItem("BottomNavigationBars").then(result => {
                        console.log(result[3].Whatsapp.PhoneNumber, result[3].Whatsapp.Description)
                        this.whatsAppPhoneNumber = result[3].Whatsapp.PhoneNumber
                        this.whatsAppText = result[3].Whatsapp.Description
                        console.log(this.whatsAppPhoneNumber)
                        this.whatsappHREF = "https://wa.me/" + this.whatsAppPhoneNumber + "?text=" + this.whatsAppText + "";
                        console.log(this.whatsappHREF)
                      })
                      break;

                    default:
                      break;
                  }
                }
              })
            })
          });
        });
      }
    })
  }
  ngOnInit() {
    this.platform.ready().then(res => {
      console.log("app comp, platformdetection", this.platformDetection.platformDetection())
      console.log("app", this.platform.platforms())
      if (this.platformDetection.platformDetectionForSplashScreen()) {
        // this.splashScreen.show()
      } else {
      }
      
      this.storageService.getItem("anaMenu").then(res => {
        try {
          if (res.MobileHotelDefinition) {
            if (res.MobileHotelDefinition.isAgency == 1) {
              this.showTabBar = false;
              this.isHotelItem = false; 
            }
            else{
              this.isHotelItem = true;
            }
          }
        } catch (ex) {
          // var myurl = window.location.href.toString() 
          // var hotelIDCheck: any = /HotelId=([^;]+)/.exec(myurl)[1];
          // var grupID: any = /GroupId=([^;]+)/.exec(myurl)[1];
          var hotelIDCheck: any = environment.hotelId;
          var grupID: any = environment.groupId;
          this.mobileMenuService.getMobileMenu(parseInt(hotelIDCheck), parseInt(grupID), 1).then(res => {
            if (res.MobileHotelDefinition.isAgency == 1) {
              this.showTabBar = false;
              this.isHotelItem = false;  
            }
            else{
              this.isHotelItem =  true;
            }
          }).catch(err => {
            console.log('An error was accoured while getMobileMenu()', err);
          })
        }
  
      })

    })
    this.startNetworkListener();
  }
  goToLink(url: string){
    this.InAppBrowser.create(url, '_system');

    // window.open(url, "_system");
}
  test(event) {
    
    this.platform.ready().then(res => {
      //debugger
      this.storageService.getItem("LangID").then(res => {
        //debugger
        this.translateService.switchLanguage(res)
      }).catch(err => {
        //debugger
        this.deviceLanguage = this.translateService.languageSelectorFromStringToNumber(this.findDeviceLanguage.getBrowserLang())
        console.log(this.deviceLanguage)
         this.storageService.setItem("LangID", this.deviceLanguage)
        console.log('değişti app companent testmethodu',this.deviceLanguage);

        this.translateService.switchLanguage(this.deviceLanguage)
      })

      //TAB BAR GRUP OTEL SAYFASINDA, success-payment ve fail-payment sayfalarında GÖRÜNMESİNİ ENGELLEMEK İÇİN
      if (event.__ngContext__[20][0].localName == "app-group-hotel-selection-page" || event.__ngContext__[20][0].localName == "app-fail-payment" || event.__ngContext__[20][0].localName == "app-success-payment" || this.isHotelItem == false ) {
        this.showTabBar = false;
      } else {
        this.showTabBar = true
      }
      // if(window.location.href.match("sedna360mobileb2c"))

      this.storageService.getItem("themeBackgroundColor").then(res => {
        this.themeBackgroundColor = res;
      }).catch(err => {
        // alert("backgroundcolortheme test() hatası")
      });
      this.storageService.getItem("themeColor").then(res => {
        this.themeColor = res

      }).catch(err => {
        // alert("themecolor test() hatası")
      });
      this.storageService.getItem("anaMenu").then(res => {
        if (res == null || res.length === 0) {
        } else {
          this.otelName = res.MobileHotelDefinition.Name;
          this.otelLogoURL = res.MobileHotelDefinition.MobileApplicationDefinition.LogoUrl;
          this.ShowWeather = res.MobileHotelDefinition.ShowWeather;
          this.appPages = res.MainMenus;
          console.log(this.appPages, "main menü")
        }
      }).catch(err => {
        // alert("anamenu test() hatası")
      })
      this.storageService.getItem("BottomNavigationBars").then(res => {
        if (res == null || res.length === 0) {

        } else {
          for (var i = 0; i < res.length; i++) {
            switch (res[i].Key) {
              case "mobileorder":
                this.mobileOrderIsShowing = res[i].IsShowing
                this.mobileOrderMenuId = res[i].MenuId
                break;
              case "reservation":
                this.reservationBottomMenuIsShowing = res[i].IsShowing
                this.reservationMenuId = res[i].MenuId
                break;
              case "service":
                this.serviceIsShowing = res[i].IsShowing
                this.serviceMenuId = res[i].MenuId
                break;
              case "whatsapp":
                this.whatsAppIsShowing = res[i].IsShowing
                this.storageService.getItem("BottomNavigationBars").then(result => {
                  console.log(result[3].Whatsapp.PhoneNumber, result[3].Whatsapp.Description)
                  this.whatsAppPhoneNumber = result[3].Whatsapp.PhoneNumber
                  this.whatsAppText = result[3].Whatsapp.Description
                  console.log(this.whatsAppPhoneNumber)
                  this.whatsappHREF = "https://wa.me/" + this.whatsAppPhoneNumber + "?text=" + this.whatsAppText + "";
                  console.log(this.whatsappHREF)
                }).catch(err => {
                  // alert("BottomNavigationBars test() hatası 2")
                })

                break;

              default:
                break;
            }
          }
        }
      }).catch(err => {
      })
    })
  }
  goTo(url) {
    // this.router.navigate([url])
    console.log(url)
    this.routelink.getRouteLinkParameters(url, {})
  }
  changeOtel() {
    this.storageService.removeItem("profile")
    this.storageService.removeItem("FavoritePages")
    this.storageService.getItem("hotelId").then(hotelid => {
      this.storageService.getItem("groupId").then(groupid => {
        this.router.navigateByUrl('/group-hotel-selection-page;HotelId=' + hotelid + ';GroupId=' + groupid + '')
      })
    })
  }
  presentLoginModal(): Promise<any> {
    return this.modalControllerService.presentModal(ModalTpyes.login, "no data needed");
  }
  selectMenu(selectedItem: any): void {
    var obj = new redirectModel();
    obj.ParentId = selectedItem.RecId;
    obj.PageType = selectedItem.Type;
    obj.PageTitle = selectedItem.Name;
    if (obj.PageType == MenuTypes.sedna360Survey) {
      if (selectedItem.ParentId == 0) {
        obj.ParentId = 0;
      }
      this.storageService.getItem("profile").then(res => {
        if (res) {
          console.log("profile login")
          var guestId = res.guestId;
          var reservationId = res.reservationId
          var surveyId = selectedItem.par3
          this.storageService.getItem("anaMenu").then(res => {
            if (res) {
              var companyId = res.MobileHotelDefinition.CompaniesId
              this.storageService.getItem("LangID").then(res => {
                this.link = environment.surveyAddress + "/#/public/survey?id=" + surveyId + "&guestId=" + guestId + "&reservationId=" + reservationId + "&lang=" + this.translateService.languageSelector(res) + "&companyId=" + companyId + "&from=link";
                var surveyProperty = { Remark: "", Link: "" }
                surveyProperty.Remark = selectedItem.Remark
                surveyProperty.Link = this.link
                this.routelink.redirectViaPageType(obj, surveyProperty)
              })

            } else {
              console.log("ana menü boş")
            }
          })
        } else {
          this.presentLoginModal().then(res => { })
        }
      }).catch(err => {
        this.presentLoginModal().then(res => { })
      })
    }
    else if (obj.PageType == MenuTypes.internetLink) {
      this.routelink.redirectViaPageType(obj, selectedItem.Link)
    } else if (selectedItem.Type == MenuTypes.html) {
      this.routelink.redirectViaPageType(obj, selectedItem)
    }
    else if (selectedItem.Type == MenuTypes.video) {
      this.modalControllerService.presentModal(selectedItem.Type, selectedItem)
    }
    else if (selectedItem.Type == MenuTypes.sss) {
      this.routelink.redirectViaPageType(obj, selectedItem)
    }

    else if (obj.PageType == MenuTypes.preCheckinLink) {
      var preCheckinProperty = {
        Remark: selectedItem.Name,
        Link: selectedItem.Link
      }
      this.storageService.getItem("LangID").then(res => {
        this.deviceLanguage = res  
        this.storageService.getItem("profile").then(res => {
          if (res == null || res == undefined) {
            preCheckinProperty.Link = preCheckinProperty.Link.concat("&lang=" + this.translateService.languageSelector(this.deviceLanguage))
            this.routelink.redirectViaPageType(obj, preCheckinProperty)
          } else {
            preCheckinProperty.Link = preCheckinProperty.Link.concat("&lang=" + this.translateService.languageSelector(this.deviceLanguage))
            preCheckinProperty.Link = preCheckinProperty.Link.concat("&reservationId=" + res.EncryptReservationId)
            this.routelink.redirectViaPageType(obj, preCheckinProperty)
          }

        }).catch(err => {
          preCheckinProperty.Link = preCheckinProperty.Link.concat("&lang=" + this.translateService.languageSelector(this.deviceLanguage))
          this.routelink.redirectViaPageType(obj, preCheckinProperty)
        })
      })

    }
    else {
      this.routelink.redirectViaPageType(obj, "undefined")
    }


    console.log(selectedItem, "selectedMenuItem")
  }
  routeWithTabButton(path: string) {
    console.log("tab button ", path)
    this.routelink.getRouteLinkParameters(path, "undefined")
  }

  async isHotel():Promise<boolean>{
    
    return this.storageService.getItem("anaMenu").then(res => {

      // if (res.MobileHotelDefinition) {
      //   if (res.MobileHotelDefinition.isAgency == 1) {
      //     this.showTabBar = false;
      //     return false;  
      //   }
      //   else{
      //     return  true;
      //   }
      // }

      try {
        if (res.MobileHotelDefinition) {
          if (res.MobileHotelDefinition.isAgency == 1) {
            this.showTabBar = false;
            return false;  
          }
          else{
            return  true;
          }
        }
      } catch (ex) {
        var myurl = window.location.href.toString() 
        var hotelIDCheck: any = /HotelId=([^;]+)/.exec(myurl)[1];
        var grupID: any = /GroupId=([^;]+)/.exec(myurl)[1];

        this.mobileMenuService.getMobileMenu(parseInt(hotelIDCheck), parseInt(grupID), 1).then(res => {
          if (res.MobileHotelDefinition.isAgency == 1) {
            this.showTabBar = false;
            return false;  
          }
          else{
            return  true;
          }
        }).catch(err => {
          console.log('An error was accoured while getMobileMenu()', err);
        })
      }

    })
  }

  routeWithDynamicBottomMenuItems(menuId) {

    for (var i = 0; i < this.appPages.length; i++) {
      if (this.appPages[i].RecId == menuId) {
        var obj = new redirectModel();
        obj.ParentId = this.appPages[i].RecId;
        obj.PageType = this.appPages[i].Type;
        obj.PageTitle = this.appPages[i].Name;
        this.shortcutNotExist = false;
        this.routelink.redirectViaPageType(obj, "undefined")
        console.log(this.appPages[i], "selectedMenuItem")
        break;
      } else {
        this.shortcutNotExist = true;
      }
    }
    if (this.shortcutNotExist) {
      this.storageService.getItem("LangID").then(langid => {
        this.mobilemenuservice.getMenuDetailsForShortCut(menuId, langid).then(res => {
          var obj = new redirectModel();
          obj.ParentId = res.RecId
          obj.PageType = res.Type;
          obj.PageTitle = res.Name;
          this.routelink.redirectViaPageType(obj, "undefined")
        })
      })

    }
  }
  presentModal(modalType: ModalTpyes) {
    this.modalControllerService.presentModal(modalType, undefined);
  }
}
